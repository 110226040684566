import React from "react";

import Layout from "../components/layout-main";
import { ContactForm, FormTypes } from "../components/form-contact";
import { HeroSplashSection } from "../components/sections/hero-splash";
import { SocialIcons } from "../components/social-icons";
import { CalendlyEventTypes } from "../components/form-calendly";

/** @jsx jsx */
import { jsx, Grid } from "theme-ui";

const GplPage = ({ location }) => {
  React.useEffect(() => {
    let input = document.querySelector("input[type=email]");
    input && input.focus();
  }, []);

  return (
    <Layout location={location} pageTitle="Contact Us" hideSubscribe>
      <HeroSplashSection
        id="hero-splash"
        // title={<>Consumers are changing;<br />shouldn't your software?</>}
        // title={"The only software you'll need to manage your funeral home."}
        title={
          <>
            Are you prepared
            <br />
            for the FTC changes?
          </>
        }
        subtitle={"Enhance your GPL"}
        description={
          "We've analyzed every funeral home website in America. We can help you meet all the new requirements. Sign up, and we'll email you to discuss your options."
        }
        imgSrc={"https://media.afterword.com/site/pricing-map-2022.png"}
        imgWidth={["100%", "600px"]}
        cta={
          <ContactForm
            buttonVariant="blueButton"
            formType={FormTypes.GPL}
            formTitle=""
            formOutro="Thanks for signing up. Our team will reach out to you shortly."
            hideImage={true}
            buttonText="Sign up"
            hideCalendly
          />
        }
      ></HeroSplashSection>

      {/* <section className="aw-accent">
        <div className="aw-content" sx={{
            maxWidth: "600px",
            textAlign: "center",
        }}>

        
          <h1>Enhance your GPL</h1>

          <p>Are you prepared for the FTC’s proposed changes to the Funeral Rule? We've analyzed every funeral home website in America, and we can help you to meet (and exceed) the new requirements. </p>

          <p>Sign up, and we'll email you to discuss your options</p>

          <div>
              <ContactForm
                formSubtitle=""
                formOutro="Thanks. We’ll reach out soon for more details on how we can help."
                buttonVariant="blueButton"
                buttonText="Submit"
                formType={FormTypes.GPL}
                hideCalendly
              />
            </div>
        </div>
      </section> */}
    </Layout>
  );
};

export default GplPage;
